/* eslint-disable */
import * as R from 'ramda';
import _ from 'lodash';
import moment from 'moment-timezone';
import update from 'immutability-helper';
import { switchPath } from 'utilsModule';
import { ROLE_MAPPING } from 'dataModule/constants/profile';

const ap = {
  be2fe: ({
    category: condition,
    createdBy: {
      demographics: { name: createdBy, created_profile_photo },
    },
    createdAt,
    updatedBy: {
      demographics: { name: updatedBy, updated_profile_photo },
    },
    updatedAt,
    ...rest
  }) => ({
    condition,
    created: {
      avatar: created_profile_photo || {},
      name: createdBy || {},
      at: createdAt,
    },
    modified: {
      avatar: updated_profile_photo || {},
      name: updatedBy || {},
      at: updatedAt,
    },
    ...rest,
  }),
};

const pp = {
  be2fe: ({
    category: condition,
    createdBy: { demographics: { name: createdBy } = {} } = {},
    createdAt,
    updatedBy: { demographics: { name: updatedBy } = {} } = {},
    updatedAt,
    consentForm = {},
    loanForm = {},
    enrolledProgramConsumables = [],
    deviceRecords = [],
    enrolledProgramCareteam = {},
    enrolledProgramAdditionalMember = [],
    isKiosk = false,
    ...rest
  }) => ({
    ...rest,
    condition,
    created: {
      name: createdBy || {},
      at: createdAt,
    },
    modified: {
      name: updatedBy || {},
      at: updatedAt,
    },
    forms: [
      {
        type: 'consent',
        contractId: R.path(['contractId'], consentForm),
        uri: R.path(['uri'], consentForm),
        date: R.path(['date'], consentForm),
      },
      {
        type: 'loan',
        contractId: R.path(['contractId'], loanForm),
        uri: R.path(['uri'], loanForm),
        date: R.path(['date'], loanForm),
      },
    ],
    consumables: R.map(
      ({
        vitalGroupId,
        id,
        lotNumber: lotNo,
        quantity,
        expiredDate: expiryDate,
        sku: { name, description = '', type, model, imageUri: photo } = {},
        chargeItemCode: { currency, priceBeforeTax: price, priceAfterTax } = {},
      }) => ({
        vitalGroupId,
        id: id || lotNo,
        name,
        photo,
        label: name,
        description,
        lotNo,
        quantity,
        expiryDate,
        price,
      }),
    )(enrolledProgramConsumables),
    devices: R.map(R.propOr({}, 'device'))(deviceRecords),
    careteams: [
      (({ careTeamId: id, providers = [], ...rest }) => ({
        ...rest,
        id,
        participant: R.map(({ provider: { currentDemographics, ...others }, ...rest }) => ({
          profile: { ...others, ...currentDemographics },
          ...rest,
        }))(providers),
      }))(enrolledProgramCareteam),
    ],
    additionalMember: R.map(
      ({
        isPrimary,
        provider: {
          id,
          orgId,
          speciality,
          type,
          currentDemographics: { mobile, name: { firstName, lastName }, profile_photo } = {},
        } = {},
        ...rest
      }) => {
        return {
          profile: {
            id,
            role: {
              value: ROLE_MAPPING.find((r) => r.id === type).value,
              label: ROLE_MAPPING.find((r) => r.id === type).label,
            },
            orgId,
            specialty: { value: speciality, label: speciality },
          },
          demographic: {
            id,
            mobile,
            identity: {
              avatar: profile_photo,
              firstName,
              lastName,
            },
          },
          primary: isPrimary,
          ...rest,
        };
      },
    )(enrolledProgramAdditionalMember),
    isKiosk,
  }),
};

const ottPP = {
  be2fe: ({ deviceRecords = [], isKiosk = false, title = title, ...rest }) => ({
    ...rest,
    devices: R.map(R.propOr({}, 'device'))(deviceRecords),
    title,
    isKiosk,
  }),
};

const createAP = {
  fe2be: ({
    info: {
      title,
      description = '',
      condition: { value: category },
      duration: {
        length,
        calendar: { value: calendar },
      },
      issueDevice,
    },
    vitals,
    careteams = [],
    pricing,
    isKiosk = false,
  }) => ({
    issueDevice: issueDevice == 'issueDevice' ? true : false,
    title,
    description,
    category,
    status: 'READY',
    duration: { length, calendar },
    vitals: R.pipe(
      R.filter(R.prop('selected')),
      R.mapObjIndexed(
        (
          {
            dimensions,
            schedule: {
              type: { value: type },
              timeslots,
              frequency,
              meals,
            },
            sku: { value: skuId },
            patIns: patientInstructions = '',
            isContinuousMonitoringEnabled,
          },
          vitalGroupName,
        ) => {
          if (isContinuousMonitoringEnabled === false) {
            return {
              vitalGroupName,
              isContinuousMonitoringEnabled,
              thresholds: R.map(
                ({ threshold: { criticallyLow = null, low = null, high = null, criticallyHigh = null } = {} }) => [
                  criticallyLow,
                  low,
                  high,
                  criticallyHigh,
                ],
              )(dimensions),
              schedule: {
                taskType: type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                scheduleType: {
                  frequency: 'FREQUENCY',
                  timeslots: 'TIMESLOT',
                  meals: 'TIMESLOT_MEAL',
                  date: 'ONETIME',
                }[type],
                scheduleDetails: (() => {
                  switch (type) {
                    case 'frequency':
                      return {
                        frequency: {
                          calendar: R.path(['calendar', 'value'], frequency),
                          times: R.path(['times'], frequency),
                        },
                      };
                    case 'timeslots':
                      return {
                        timeslot: R.map(
                          ({ from, to }) => ({
                            start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                              moment(from).minute(),
                            ).padStart(2, '0')}`,
                            end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                              2,
                              '0',
                            )}`,
                          }),
                          timeslots,
                        ),
                      };
                    case 'meals':
                      return {
                        timeslotMeal: {
                          calendar: 'DAILY',
                          timeslots: (({
                            before: { checkeds: beforeCheckeds = {} } = {},
                            after: { checkeds: afterCheckeds = {} } = {},
                          }) =>
                            R.pipe(
                              R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                              R.flatten,
                              R.map((meal) => ({ meal, times: 1 })),
                            )([beforeCheckeds, afterCheckeds]))(meals),
                        },
                      };
                    default:
                      return {};
                  }
                })(),
              },
              notifications: R.pipe(
                R.mapObjIndexed(({ alerts: { alertCategory: { oneTime, advanced } = {} } = {} }, vitalDim) => {
                  // Neither one time nor advanced alerts
                  if (!R.prop('selected', oneTime) && !R.prop('selected', advanced)) {
                    return [];
                  }

                  const alertCategory = R.prop('selected', advanced) ? 'ADVANCED' : 'ONE-TIME';

                  const {
                    missed: { missed },
                    warning: { low, high },
                    critical: { criticallyLow, criticallyHigh },
                  } = R.prop('alertConfig', R.prop('selected', advanced) ? advanced : oneTime);

                  const toNotification = (alertType, alertInput) => {
                    const {
                      times: { value: times = 1 } = {},
                      sendToCareteamVia: { checkeds: careteamCheckeds = {} } = {},
                      sendToPatientVia: { checkeds: patientCheckeds = {} } = {},
                    } = alertInput;

                    const isAlertSet = R.pipe(
                      R.ap([R.values]),
                      R.flatten,
                      R.any(R.identity),
                    )([careteamCheckeds, patientCheckeds]);

                    return isAlertSet
                      ? {
                          // eslint-disable-next-line
                          alertCategory,
                          alertType:
                            alertType === 'missed'
                              ? vitalGroupName === 'ECG'
                                ? 'MISSED_UPLOAD_ECG'
                                : 'MISSED_READING'
                              : 'THRESHOLD_READING',
                          consecutiveCount: times,
                          ...(alertType !== 'missed'
                            ? {
                                trigger: [
                                  { low: 'L', high: 'H', criticallyLow: 'LL', criticallyHigh: 'HH' }[alertType],
                                ],
                              }
                            : {}),
                          messages: R.pipe(
                            R.ap([
                              ({ email, sms, inApp }) =>
                                R.filter(R.identity, [email && 'EMAIL', sms && 'SMS', inApp && 'INAPP']),
                            ]),
                            ([careteam, patient]) => [
                              { recipient: 'CARETEAM', type: careteam, subject: 'Alert for Care Team' },
                              { recipient: 'PATIENT', type: patient, subject: 'Alert for Participant' },
                            ],
                          )([careteamCheckeds, patientCheckeds]),
                        }
                      : null;
                  };

                  const notificationFinal = R.pipe(
                    R.filter(R.identity),
                    R.map(R.assoc('vitalDim', vitalDim)),
                  )([
                    toNotification('missed', missed),
                    toNotification('low', low),
                    toNotification('high', high),
                    toNotification('criticallyLow', criticallyLow),
                    toNotification('criticallyHigh', criticallyHigh),
                  ]);

                  // HB-402 > Backend - Alert for Blood Pressure Vital
                  // Special handling for Blood Pressure
                  // Diastolic will always follow Systolic alert settings
                  if (vitalDim === 'dia') {
                    return null;
                  }
                  if (vitalDim === 'sys') {
                    return notificationFinal.concat(
                      R.pipe(
                        R.filter(R.identity),
                        R.map(R.assoc('vitalDim', 'dia')),
                      )([
                        toNotification('missed', missed),
                        toNotification('low', low),
                        toNotification('high', high),
                        toNotification('criticallyLow', criticallyLow),
                        toNotification('criticallyHigh', criticallyHigh),
                      ]),
                    );
                  }
                  return notificationFinal;
                }),
                R.filter(R.identity),
                R.values,
                R.flatten,
              )(dimensions),
              skuId,
              patientInstructions,
            };
          } else {
            const vitals = Object.keys(dimensions);
            let groupedVitals = [];
            const groupDimCM = [];
            const groupDimSpot = [];

            let thresholdsCM = {};
            let thresholdsSpot = {};
            let groupCM = {};

            const extractNotification = (vital, selectedVital) => {
              const { alerts: { alertCategory: { oneTime, advanced } = {} } = {} } = selectedVital;
              const vitalDim = vital;
              if (!R.prop('selected', oneTime) && !R.prop('selected', advanced)) {
                return [];
              }

              const alertCategory = R.prop('selected', advanced) ? 'ADVANCED' : 'ONE-TIME';

              const {
                missed: { missed },
                warning: { low, high },
                critical: { criticallyLow, criticallyHigh },
              } = R.prop('alertConfig', R.prop('selected', advanced) ? advanced : oneTime);

              const toNotification = (alertType, alertInput) => {
                const {
                  times: { value: times = 1 } = {},
                  hours: { value: hours = 0 } = {},
                  minutes: { value: minutes = 0 } = {},
                  sendToCareteamVia: { checkeds: careteamCheckeds = {} } = {},
                  sendToPatientVia: { checkeds: patientCheckeds = {} } = {},
                } = alertInput;

                const isAlertSet = R.pipe(
                  R.ap([R.values]),
                  R.flatten,
                  R.any(R.identity),
                )([careteamCheckeds, patientCheckeds]);

                return isAlertSet
                  ? {
                      // eslint-disable-next-line
                      alertCategory,
                      alertType:
                        alertType === 'missed'
                          ? vitalGroupName === 'ECG'
                            ? 'MISSED_UPLOAD_ECG'
                            : 'MISSED_READING'
                          : 'THRESHOLD_READING',
                      consecutiveCount: times,
                      duration: {
                        hours: alertType !== 'missed' ? 0 : hours,
                        minutes,
                        seconds: 0
                      },
                      ...(alertType !== 'missed'
                        ? {
                            trigger: [{ low: 'L', high: 'H', criticallyLow: 'LL', criticallyHigh: 'HH' }[alertType]],
                          }
                        : {}),
                      messages: R.pipe(
                        R.ap([
                          ({ email, sms, inApp }) =>
                            R.filter(R.identity, [email && 'EMAIL', sms && 'SMS', inApp && 'INAPP']),
                        ]),
                        ([careteam, patient]) => [
                          { recipient: 'CARETEAM', type: careteam, subject: 'Alert for Care Team' },
                          { recipient: 'PATIENT', type: patient, subject: 'Alert for Participant' },
                        ],
                      )([careteamCheckeds, patientCheckeds]),
                    }
                  : null;
              };

              const notificationFinal = R.pipe(
                R.filter(R.identity),
                R.map(R.assoc('vitalDim', vitalDim)),
              )([
                toNotification('missed', missed),
                toNotification('low', low),
                toNotification('high', high),
                toNotification('criticallyLow', criticallyLow),
                toNotification('criticallyHigh', criticallyHigh),
              ]);

              // HB-402 > Backend - Alert for Blood Pressure Vital
              // Special handling for Blood Pressure
              // Diastolic will always follow Systolic alert settings
              if (vitalDim === 'dia') {
                return null;
              }
              if (vitalDim === 'sys') {
                return notificationFinal.concat(
                  R.pipe(
                    R.filter(R.identity),
                    R.map(R.assoc('vitalDim', 'dia')),
                  )([
                    toNotification('missed', missed),
                    toNotification('low', low),
                    toNotification('high', high),
                    toNotification('criticallyLow', criticallyLow),
                    toNotification('criticallyHigh', criticallyHigh),
                  ]),
                );
              }

              console.log(">> notificationFinal: ", notificationFinal)
              return notificationFinal;
            };

            let notificationsCM = [];

            vitals.forEach((vital) => {
              const selectedVital = R.path([vital], dimensions);
              if (selectedVital.isContinuousMonitoring) {
                const {
                  criticallyLow = null,
                  low = null,
                  high = null,
                  criticallyHigh = null,
                } = selectedVital.threshold;
                thresholdsCM[vital] = [criticallyLow, low, high, criticallyHigh];
                groupCM = selectedVital;
                if(vital !== 'dia'){
                  const notificationVital = extractNotification(vital, selectedVital);
                  notificationsCM = [...notificationsCM, ...notificationVital]
                }
              } else {
                if (!['sys', 'dia'].includes(vital)) {
                  const {
                    criticallyLow = null,
                    low = null,
                    high = null,
                    criticallyHigh = null,
                  } = selectedVital.threshold;
                  thresholdsSpot[vital] = [criticallyLow, low, high, criticallyHigh];

                  groupDimSpot.push({
                    thresholds: thresholdsSpot,
                    schedule: {
                      taskType:
                        selectedVital.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                      monitoringType: 'NON_CONTINUOUS',
                      scheduleType: {
                        frequency: 'FREQUENCY',
                        timeslots: 'TIMESLOT',
                        meals: 'TIMESLOT_MEAL',
                        date: 'ONETIME',
                      }[selectedVital.schedule.type.value],
                      scheduleDetails: (() => {
                        const {
                          schedule: {
                            type: { value: type },
                            timeslots,
                            frequency,
                            meals,
                          },
                        } = selectedVital;
                        switch (type) {
                          case 'frequency':
                            return {
                              frequency: {
                                calendar: R.path(['calendar', 'value'], frequency),
                                times: R.path(['times'], frequency),
                              },
                            };
                          case 'timeslots':
                            return {
                              timeslot: R.map(
                                ({ from, to }) => ({
                                  start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                                    moment(from).minute(),
                                  ).padStart(2, '0')}`,
                                  end: `${String(moment(to).hour()).padStart(2, '0')}${String(
                                    moment(to).minute(),
                                  ).padStart(2, '0')}`,
                                }),
                                timeslots,
                              ),
                            };
                          case 'meals':
                            return {
                              timeslotMeal: {
                                calendar: 'DAILY',
                                timeslots: (({
                                  before: { checkeds: beforeCheckeds = {} } = {},
                                  after: { checkeds: afterCheckeds = {} } = {},
                                }) =>
                                  R.pipe(
                                    R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                                    R.flatten,
                                    R.map((meal) => ({ meal, times: 1 })),
                                  )([beforeCheckeds, afterCheckeds]))(meals),
                              },
                            };
                          default:
                            return {};
                        }
                      })(),
                    },
                    notifications: extractNotification(vital, selectedVital),
                  });
                }
              }
            });

            // Handle BP NON_CONTINUOUS
            const isBP = vitals.find((vital) => vital === 'sys' || vital === 'dia');
            if (isBP) {
              const selectSys = R.path(['sys'], dimensions);
              const selectDia = R.path(['dia'], dimensions);

              const { criticallyLow = null, low = null, high = null, criticallyHigh = null } = selectSys.threshold;

              const thresholdDia = selectDia.threshold;

              groupDimSpot.push({
                thresholds: {
                  sys: [criticallyLow, low, high, criticallyHigh],
                  dia: [thresholdDia.criticallyLow, thresholdDia.low, thresholdDia.high, thresholdDia.criticallyHigh],
                },
                schedule: {
                  taskType: selectDia.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                  monitoringType: 'NON_CONTINUOUS',
                  scheduleType: {
                    frequency: 'FREQUENCY',
                    timeslots: 'TIMESLOT',
                    meals: 'TIMESLOT_MEAL',
                    date: 'ONETIME',
                  }[selectDia.schedule.type.value],
                  scheduleDetails: (() => {
                    const {
                      schedule: {
                        type: { value: type },
                        timeslots,
                        frequency,
                        meals,
                      },
                    } = selectDia;
                    switch (type) {
                      case 'frequency':
                        return {
                          frequency: {
                            calendar: R.path(['calendar', 'value'], frequency),
                            times: R.path(['times'], frequency),
                          },
                        };
                      case 'timeslots':
                        return {
                          timeslot: R.map(
                            ({ from, to }) => ({
                              start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                                moment(from).minute(),
                              ).padStart(2, '0')}`,
                              end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                                2,
                                '0',
                              )}`,
                            }),
                            timeslots,
                          ),
                        };
                      case 'meals':
                        return {
                          timeslotMeal: {
                            calendar: 'DAILY',
                            timeslots: (({
                              before: { checkeds: beforeCheckeds = {} } = {},
                              after: { checkeds: afterCheckeds = {} } = {},
                            }) =>
                              R.pipe(
                                R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                                R.flatten,
                                R.map((meal) => ({ meal, times: 1 })),
                              )([beforeCheckeds, afterCheckeds]))(meals),
                          },
                        };
                      default:
                        return {};
                    }
                  })(),
                },
                notifications: extractNotification('sys', selectSys),
              });
            }

            groupDimCM.push({
              thresholds: thresholdsCM,
              schedule: {
                taskType: groupCM.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                scheduleType: {
                  frequency: 'FREQUENCY',
                  timeslots: 'TIMESLOT',
                  meals: 'TIMESLOT_MEAL',
                  date: 'ONETIME',
                  continuous_monitoring: 'CONTINUOUS_MONITORING',
                }[groupCM.schedule.type.value],
                monitoringType: 'CONTINUOUS',
                scheduleDetails: (() => {
                  const {
                    schedule: {
                      type: { value: type },
                      timeslots,
                      frequency,
                      meals,
                    },
                  } = groupCM;
                  switch (type) {
                    case 'continuous_monitoring':
                      return {
                        display: '24 Hours',
                      };
                    case 'frequency':
                      return {
                        frequency: {
                          calendar: R.path(['calendar', 'value'], frequency),
                          times: R.path(['times'], frequency),
                        },
                      };
                    case 'timeslots':
                      return {
                        timeslot: R.map(
                          ({ from, to }) => ({
                            start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                              moment(from).minute(),
                            ).padStart(2, '0')}`,
                            end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                              2,
                              '0',
                            )}`,
                          }),
                          timeslots,
                        ),
                      };
                    case 'meals':
                      return {
                        timeslotMeal: {
                          calendar: 'DAILY',
                          timeslots: (({
                            before: { checkeds: beforeCheckeds = {} } = {},
                            after: { checkeds: afterCheckeds = {} } = {},
                          }) =>
                            R.pipe(
                              R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                              R.flatten,
                              R.map((meal) => ({ meal, times: 1 })),
                            )([beforeCheckeds, afterCheckeds]))(meals),
                        },
                      };
                    default:
                      return {};
                  }
                })(),
              },
              notifications: notificationsCM,
            });

            groupedVitals = [...groupDimCM, ...groupDimSpot];

            console.log('>> groupedVitals: ', groupedVitals);

            return {
              vitalGroupName,
              isContinuousMonitoringEnabled,
              subVitals: groupedVitals,
              skuId,
              patientInstructions,
            };
          }
        },
      ),
      R.values,
    )(vitals),
    careteam: careteams,
    devices: R.pipe(
      R.mapObjIndexed((chargeItemCodeId, dashSkuId) => ({
        skuId: R.pipe(R.split('-'), R.last, parseInt)(dashSkuId),
        chargeItemCodeId,
      })),
      R.values,
    )(pricing),
    isKiosk,
  }),
  be2fe: ({ value }) => ({ ap: ap.be2fe(value) }),
};

const createOTT = {
  fe2be: (data) => data,
  be2fe: ({ value }) => ({ ottPP: ottPP.be2fe(value) }),
};

const updateOTT = createOTT;

const retrieveAPs = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      adminPrograms,
      pagination: { page_no: page, page_size: pageSize, total },
    },
  }) => ({
    list: R.map(ap.be2fe)(adminPrograms),
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const fetchExtensionSuspension = {
  fe2be: R.identity,
  be2fe: ({
    value: {
      extensionSuspension,
      pagination: { page_no: page, page_size: pageSize, total },
    },
  }) => ({
    list: R.map(ap.be2fe)(extensionSuspension),
    pagination: {
      currentPage: page - 1,
      pageSize,
      totalCount: total,
    },
  }),
};

const retrieveAP = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ ap: ap.be2fe(value) }),
};

const updateAP = createAP;

const enrollPP = {
  fe2be: ({
    info: {
      apId,
      title,
      description = '',
      condition: { value: category },
      duration: {
        length,
        calendar: { value: calendar },
      },
      period: { start: startDate } = {},
      issueDevice,
      isAddOnAfter,
      addOnData,
    },
    forms,
    vitals,
    careteams,
    additionalMembers,
    pricing,
    epBaseId,
  }) => ({
    adminProgramId: apId,
    issueDevice: issueDevice == 'issueDevice' ? true : false,
    isAddOnAfter,
    addOnData,
    epBaseId,
    title,
    description,
    category,
    status: 'ENROLLED',
    duration: { length, calendar },
    startDate: moment(startDate).format('YYYY-MM-DD'),
    forms: R.map(({ type, url, contractId }) => ({ type, uri: url, contractId, date: moment().toISOString() }))(forms),
    vitals: R.pipe(
      R.mapObjIndexed(
        (
          {
            dimensions,
            schedule: {
              type: { value: type },
              timeslots,
              frequency,
              meals,
              date,
            },
            sku: { value: skuId },
            bmi: {
              values: {
                height,
                weight,
                bmiType: { value: bmiValue },
              },
            },
            patIns: patientInstructions = '',
            isContinuousMonitoringEnabled,
          },
          vitalGroupName,
        ) => {
          if (isContinuousMonitoringEnabled === false) {
            return {
              vitalGroupName,
              thresholds: R.map(
                ({ threshold: { criticallyLow = null, low = null, high = null, criticallyHigh = null } = {} }) => [
                  criticallyLow,
                  low,
                  high,
                  criticallyHigh,
                ],
              )(dimensions),
              schedule: {
                taskType: type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                scheduleType: {
                  frequency: 'FREQUENCY',
                  timeslots: 'TIMESLOT',
                  meals: 'TIMESLOT_MEAL',
                  date: 'ONETIME',
                }[type],
                scheduleDetails: (() => {
                  switch (type) {
                    case 'frequency':
                      return {
                        frequency: {
                          calendar: R.path(['calendar', 'value'], frequency),
                          times: R.path(['times'], frequency),
                        },
                      };
                    case 'timeslots':
                      return {
                        timeslot: R.map(
                          ({ from, to }) => ({
                            start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                              moment(from).minute(),
                            ).padStart(2, '0')}`,
                            end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                              2,
                              '0',
                            )}`,
                          }),
                          timeslots,
                        ),
                      };
                    case 'meals':
                      return {
                        timeslotMeal: {
                          calendar: 'DAILY',
                          timeslots: (({
                            before: { checkeds: beforeCheckeds = {} } = {},
                            after: { checkeds: afterCheckeds = {} } = {},
                          }) =>
                            R.pipe(
                              R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                              R.flatten,
                              R.map((meal) => ({ meal, times: 1 })),
                            )([beforeCheckeds, afterCheckeds]))(meals),
                        },
                      };
                    case 'date':
                      return {
                        onetime: {
                          start: moment(R.path(['start'], date)).format('YYYY-MM-DD'),
                        },
                      };
                    default:
                      return null;
                  }
                })(),
              },
              notifications: R.pipe(
                R.mapObjIndexed(({ alerts: { alertCategory: { oneTime, advanced } = {} } = {} }, vitalDim) => {
                  if (!R.prop('selected', oneTime) && !R.prop('selected', advanced)) return [];

                  const alertCategory = R.prop('selected', advanced) ? 'ADVANCED' : 'ONE-TIME';

                  const {
                    missed: { missed },
                    warning: { low, high },
                    critical: { criticallyLow, criticallyHigh },
                  } = R.prop('alertConfig', R.prop('selected', advanced) ? advanced : oneTime);

                  const toNotification = (alertType, alertInput) => {
                    const {
                      times: { value: times = 1 } = {},
                      sendToCareteamVia: { checkeds: careteamCheckeds = {} } = {},
                      sendToPatientVia: { checkeds: patientCheckeds = {} } = {},
                    } = alertInput;

                    const isAlertSet = R.pipe(
                      R.ap([R.values]),
                      R.flatten,
                      R.any(R.identity),
                    )([careteamCheckeds, patientCheckeds]);

                    return isAlertSet
                      ? {
                          // eslint-disable-next-line
                          alertCategory,
                          alertType:
                            alertType === 'missed'
                              ? vitalGroupName === 'ECG'
                                ? 'MISSED_UPLOAD_ECG'
                                : 'MISSED_READING'
                              : 'THRESHOLD_READING',
                          consecutiveCount: times,
                          ...(alertType !== 'missed'
                            ? {
                                trigger: [
                                  { low: 'L', high: 'H', criticallyLow: 'LL', criticallyHigh: 'HH' }[alertType],
                                ],
                              }
                            : {}),
                          messages: R.pipe(
                            R.ap([
                              ({ email, sms, inApp }) =>
                                R.filter(R.identity, [email && 'EMAIL', sms && 'SMS', inApp && 'INAPP']),
                            ]),
                            ([careteam, patient]) => [
                              { recipient: 'CARETEAM', type: careteam, subject: 'Alert for Care Team' },
                              { recipient: 'PATIENT', type: patient, subject: 'Alert for Participant' },
                            ],
                          )([careteamCheckeds, patientCheckeds]),
                        }
                      : null;
                  };

                  const notificationFinal = R.pipe(
                    R.filter(R.identity),
                    R.map(R.assoc('vitalDim', vitalDim)),
                  )([
                    toNotification('missed', missed),
                    toNotification('low', low),
                    toNotification('high', high),
                    toNotification('criticallyLow', criticallyLow),
                    toNotification('criticallyHigh', criticallyHigh),
                  ]);

                  // HB-402 > Backend - Alert for Blood Pressure Vital
                  // Special handling for Blood Pressure
                  // Diastolic will always follow Systolic alert settings
                  if (vitalDim === 'dia') {
                    return null;
                  }
                  if (vitalDim === 'sys') {
                    return notificationFinal.concat(
                      R.pipe(
                        R.filter(R.identity),
                        R.map(R.assoc('vitalDim', 'dia')),
                      )([
                        toNotification('missed', missed),
                        toNotification('low', low),
                        toNotification('high', high),
                        toNotification('criticallyLow', criticallyLow),
                        toNotification('criticallyHigh', criticallyHigh),
                      ]),
                    );
                  }
                  return notificationFinal;
                }),
                R.filter(R.identity),
                R.values,
                R.flatten,
              )(dimensions),
              skuId,
              patientInstructions,
              bmi: {
                height,
                weight,
                bmiType: bmiValue,
              },
            };
          } else {
            const vitals = Object.keys(dimensions);
            let groupedVitals = [];
            const groupDimCM = [];
            const groupDimSpot = [];

            let thresholdsCM = {};
            let thresholdsSpot = {};
            let groupCM = {};

            const extractNotification = (vital, selectedVital) => {
              const { alerts: { alertCategory: { oneTime, advanced } = {} } = {} } = selectedVital;
              const vitalDim = vital;
              if (!R.prop('selected', oneTime) && !R.prop('selected', advanced)) {
                return [];
              }

              const alertCategory = R.prop('selected', advanced) ? 'ADVANCED' : 'ONE-TIME';

              const {
                missed: { missed },
                warning: { low, high },
                critical: { criticallyLow, criticallyHigh },
              } = R.prop('alertConfig', R.prop('selected', advanced) ? advanced : oneTime);

              const toNotification = (alertType, alertInput) => {
                const {
                  times: { value: times = 1 } = {},
                  hours: { value: hours = 0 } = {},
                  minutes: { value: minutes = 0 } = {},
                  sendToCareteamVia: { checkeds: careteamCheckeds = {} } = {},
                  sendToPatientVia: { checkeds: patientCheckeds = {} } = {},
                } = alertInput;

                const isAlertSet = R.pipe(
                  R.ap([R.values]),
                  R.flatten,
                  R.any(R.identity),
                )([careteamCheckeds, patientCheckeds]);

                return isAlertSet
                  ? {
                      // eslint-disable-next-line
                      alertCategory,
                      alertType:
                        alertType === 'missed'
                          ? vitalGroupName === 'ECG'
                            ? 'MISSED_UPLOAD_ECG'
                            : 'MISSED_READING'
                          : 'THRESHOLD_READING',
                      consecutiveCount: times,
                      duration: {
                        hours: alertType !== 'missed' ? 0 : hours,
                        minutes,
                        seconds: 0
                      },
                      ...(alertType !== 'missed'
                        ? {
                            trigger: [{ low: 'L', high: 'H', criticallyLow: 'LL', criticallyHigh: 'HH' }[alertType]],
                          }
                        : {}),
                      messages: R.pipe(
                        R.ap([
                          ({ email, sms, inApp }) =>
                            R.filter(R.identity, [email && 'EMAIL', sms && 'SMS', inApp && 'INAPP']),
                        ]),
                        ([careteam, patient]) => [
                          { recipient: 'CARETEAM', type: careteam, subject: 'Alert for Care Team' },
                          { recipient: 'PATIENT', type: patient, subject: 'Alert for Participant' },
                        ],
                      )([careteamCheckeds, patientCheckeds]),
                    }
                  : null;
              };

              const notificationFinal = R.pipe(
                R.filter(R.identity),
                R.map(R.assoc('vitalDim', vitalDim)),
              )([
                toNotification('missed', missed),
                toNotification('low', low),
                toNotification('high', high),
                toNotification('criticallyLow', criticallyLow),
                toNotification('criticallyHigh', criticallyHigh),
              ]);

              // HB-402 > Backend - Alert for Blood Pressure Vital
              // Special handling for Blood Pressure
              // Diastolic will always follow Systolic alert settings
              if (vitalDim === 'dia') {
                return null;
              }
              if (vitalDim === 'sys') {
                return notificationFinal.concat(
                  R.pipe(
                    R.filter(R.identity),
                    R.map(R.assoc('vitalDim', 'dia')),
                  )([
                    toNotification('missed', missed),
                    toNotification('low', low),
                    toNotification('high', high),
                    toNotification('criticallyLow', criticallyLow),
                    toNotification('criticallyHigh', criticallyHigh),
                  ]),
                );
              }
              return notificationFinal;
            };

            let notificationsCM = [];

            vitals.forEach((vital) => {
              const selectedVital = R.path([vital], dimensions);
              if (selectedVital.isContinuousMonitoring) {
                const {
                  criticallyLow = null,
                  low = null,
                  high = null,
                  criticallyHigh = null,
                } = selectedVital.threshold;
                thresholdsCM[vital] = [criticallyLow, low, high, criticallyHigh];
                groupCM = selectedVital;
                if(vital !== 'dia'){
                  const notification = extractNotification(vital, selectedVital);
                  notificationsCM = [...notificationsCM, ...notification];
                }
              } else {
                if (!['sys', 'dia'].includes(vital)) {
                  const {
                    criticallyLow = null,
                    low = null,
                    high = null,
                    criticallyHigh = null,
                  } = selectedVital.threshold;
                  thresholdsSpot[vital] = [criticallyLow, low, high, criticallyHigh];
                  groupDimSpot.push({
                    thresholds: thresholdsSpot,
                    schedule: {
                      taskType:
                        selectedVital.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                      monitoringType: 'NON_CONTINUOUS',
                      scheduleType: {
                        frequency: 'FREQUENCY',
                        timeslots: 'TIMESLOT',
                        meals: 'TIMESLOT_MEAL',
                        date: 'ONETIME',
                      }[selectedVital.schedule.type.value],
                      scheduleDetails: (() => {
                        const {
                          schedule: {
                            type: { value: type },
                            timeslots,
                            frequency,
                            meals,
                          },
                        } = selectedVital;
                        switch (type) {
                          case 'frequency':
                            return {
                              frequency: {
                                calendar: R.path(['calendar', 'value'], frequency),
                                times: R.path(['times'], frequency),
                              },
                            };
                          case 'timeslots':
                            return {
                              timeslot: R.map(
                                ({ from, to }) => ({
                                  start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                                    moment(from).minute(),
                                  ).padStart(2, '0')}`,
                                  end: `${String(moment(to).hour()).padStart(2, '0')}${String(
                                    moment(to).minute(),
                                  ).padStart(2, '0')}`,
                                }),
                                timeslots,
                              ),
                            };
                          case 'meals':
                            return {
                              timeslotMeal: {
                                calendar: 'DAILY',
                                timeslots: (({
                                  before: { checkeds: beforeCheckeds = {} } = {},
                                  after: { checkeds: afterCheckeds = {} } = {},
                                }) =>
                                  R.pipe(
                                    R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                                    R.flatten,
                                    R.map((meal) => ({ meal, times: 1 })),
                                  )([beforeCheckeds, afterCheckeds]))(meals),
                              },
                            };
                          default:
                            return {};
                        }
                      })(),
                    },
                    notifications: extractNotification(vital, selectedVital),
                  });
                }
              }
            });

            // Handle BP NON_CONTINUOUS
            const isBP = vitals.find((vital) => vital === 'sys' || vital === 'dia');
            if (isBP) {
              const selectSys = R.path(['sys'], dimensions);
              const selectDia = R.path(['dia'], dimensions);

              const { criticallyLow = null, low = null, high = null, criticallyHigh = null } = selectSys.threshold;

              const thresholdDia = selectDia.threshold;

              groupDimSpot.push({
                thresholds: {
                  sys: [criticallyLow, low, high, criticallyHigh],
                  dia: [thresholdDia.criticallyLow, thresholdDia.low, thresholdDia.high, thresholdDia.criticallyHigh],
                },
                schedule: {
                  taskType: selectDia.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                  monitoringType: 'NON_CONTINUOUS',
                  scheduleType: {
                    frequency: 'FREQUENCY',
                    timeslots: 'TIMESLOT',
                    meals: 'TIMESLOT_MEAL',
                    date: 'ONETIME',
                  }[selectDia.schedule.type.value],
                  scheduleDetails: (() => {
                    const {
                      schedule: {
                        type: { value: type },
                        timeslots,
                        frequency,
                        meals,
                      },
                    } = selectDia;
                    switch (type) {
                      case 'frequency':
                        return {
                          frequency: {
                            calendar: R.path(['calendar', 'value'], frequency),
                            times: R.path(['times'], frequency),
                          },
                        };
                      case 'timeslots':
                        return {
                          timeslot: R.map(
                            ({ from, to }) => ({
                              start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                                moment(from).minute(),
                              ).padStart(2, '0')}`,
                              end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                                2,
                                '0',
                              )}`,
                            }),
                            timeslots,
                          ),
                        };
                      case 'meals':
                        return {
                          timeslotMeal: {
                            calendar: 'DAILY',
                            timeslots: (({
                              before: { checkeds: beforeCheckeds = {} } = {},
                              after: { checkeds: afterCheckeds = {} } = {},
                            }) =>
                              R.pipe(
                                R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                                R.flatten,
                                R.map((meal) => ({ meal, times: 1 })),
                              )([beforeCheckeds, afterCheckeds]))(meals),
                          },
                        };
                      default:
                        return {};
                    }
                  })(),
                },
                notifications: extractNotification('sys', selectSys),
              });
            }

            groupDimCM.push({
              thresholds: thresholdsCM,
              schedule: {
                taskType: groupCM.schedule.type === 'frequency' || vitalGroupName !== 'BG' ? 'NON_BG' : 'BG',
                scheduleType: {
                  frequency: 'FREQUENCY',
                  timeslots: 'TIMESLOT',
                  meals: 'TIMESLOT_MEAL',
                  date: 'ONETIME',
                  continuous_monitoring: 'CONTINUOUS_MONITORING',
                }[groupCM.schedule.type.value],
                monitoringType: 'CONTINUOUS',
                scheduleDetails: (() => {
                  const {
                    schedule: {
                      type: { value: type },
                      timeslots,
                      frequency,
                      meals,
                    },
                  } = groupCM;
                  switch (type) {
                    case 'continuous_monitoring':
                      return {
                        display: '24 Hours',
                      };
                    case 'frequency':
                      return {
                        frequency: {
                          calendar: R.path(['calendar', 'value'], frequency),
                          times: R.path(['times'], frequency),
                        },
                      };
                    case 'timeslots':
                      return {
                        timeslot: R.map(
                          ({ from, to }) => ({
                            start: `${String(moment(from).hour()).padStart(2, '0')}${String(
                              moment(from).minute(),
                            ).padStart(2, '0')}`,
                            end: `${String(moment(to).hour()).padStart(2, '0')}${String(moment(to).minute()).padStart(
                              2,
                              '0',
                            )}`,
                          }),
                          timeslots,
                        ),
                      };
                    case 'meals':
                      return {
                        timeslotMeal: {
                          calendar: 'DAILY',
                          timeslots: (({
                            before: { checkeds: beforeCheckeds = {} } = {},
                            after: { checkeds: afterCheckeds = {} } = {},
                          }) =>
                            R.pipe(
                              R.ap([R.pipe(R.filter(R.identity), R.keys)]),
                              R.flatten,
                              R.map((meal) => ({ meal, times: 1 })),
                            )([beforeCheckeds, afterCheckeds]))(meals),
                        },
                      };
                    default:
                      return {};
                  }
                })(),
              },
              notifications: notificationsCM,
            });

            groupedVitals = [...groupDimCM, ...groupDimSpot];

            return {
              vitalGroupName,
              isContinuousMonitoringEnabled,
              subVitals: groupedVitals,
              skuId,
              patientInstructions,
              bmi: {
                height,
                weight,
                bmiType: bmiValue,
              },
            };
          }
        },
      ),
      R.values,
    )(vitals),
    careteam: R.map(R.pick(['id']))(careteams),
    additionalMembers: R.map(({ profile: { id: providerId } }) => ({
      providerId,
      isPrimary: 0,
    }))(additionalMembers),
    devices: _.keys(vitals).map((key, i) => {
      const skuId = R.pipe(R.split('-'), R.last, parseInt)(Object.keys(pricing)[i]);
      const { value: deviceSkuId } = R.map(R.prop('sku'))(R.values(vitals))[i];
      const { value, options } = R.map(R.prop('device'))(R.values(vitals))[i];
      const deviceObj = options.find((d) => d.value === value);
      const deviceId = deviceObj ? deviceObj.id : null;
      return {
        skuId,
        deviceSkuId: deviceSkuId !== skuId ? deviceSkuId : null, // no device is selected
        chargeItemCodeId: Object.values(pricing)[i],
        deviceMode: R.map(R.prop('deviceMode'))(R.values(vitals))[i],
        address: R.map(R.prop('address'))(R.values(vitals))[i],
        deviceId,
      };
    }),
    consumables: R.pipe(
      R.values,
      R.reduce(
        (accConsumables, { vitalGroupId, consumables }) =>
          R.merge(accConsumables, R.map(R.assoc('vitalGroupId', vitalGroupId))(consumables)),
        {},
      ),
      R.map(({ id: consumableId, quantity, vitalGroupId }) => ({
        consumableId,
        quantity,
        vitalGroupId,
      })),
      R.values,
    )(vitals),
  }),
  be2fe: R.identity,
};

const retrievePPs = {
  fe2be: R.identity,
  be2fe: ({ value: { enrolledPrograms } }) => ({
    list: R.map(pp.be2fe)(enrolledPrograms),
    // BE: Support pagination for this api
  }),
};

const retrieveListWithBasedPrograms = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const retrieveAllPPs = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

const retrievePP = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ pp: pp.be2fe(value) }),
};

const retrieveOTTPP = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ ottPP: ottPP.be2fe(value) }),
};

const deleteOTT = {
  fe2be: R.identity,
  be2fe: ({ value }) => ({ value }),
};

// BE: Standardize with BE so we can use enrollPP for updatePP. Probably not since there is still diff between updatePP & enrollPP
const updatePP = update(enrollPP, {
  fe2be: (enrollPP_fe2be) =>
    R.pipe(
      R.juxt([enrollPP_fe2be, R.propOr([], 'careteams'), R.propOr({}, 'vitals')]),
      ([bePayload, careteams, vitals]) => ({
        ...R.evolve({
          vitals: R.map(({ vitalGroupName, ...rest }) => ({
            ...rest,
            vitalGroupName,
            id: R.path([vitalGroupName, 'id'], vitals),
          })),
        })(bePayload),
        careteam: {
          careTeamId: R.path([0, 'id'], careteams),
          providers: R.map(({ id: providerId, primary: isPrimary }) => ({ providerId, isPrimary }))(
            R.path([0, 'members'], careteams),
          ),
        },
      }),
    ),
  be2fe: { $set: ({ value }) => ({ pp: pp.be2fe(value) }) },
});

const dischargePP = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveCurrentOrgApNames = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveEPCareteamMovementHistory = {
  fe2be: R.identity,
  be2fe: ({ value: { data } }) => ({
    list: data,
  }),
};

const programTimeSpentReport = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveListPDFPatientVitalReport = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const retrieveEnrolledProgrammesByStatusAndPatient = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const releaseKiosk = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const updateKioskStatus = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const getAdherencePdfDetail = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  createAP,
  retrieveAPs,
  retrieveAP,
  updateAP,
  enrollPP,
  retrievePPs,
  retrievePP,
  retrieveListWithBasedPrograms,
  updatePP,
  dischargePP,
  retrieveCurrentOrgApNames,
  retrieveEPCareteamMovementHistory,
  programTimeSpentReport,
  retrieveListPDFPatientVitalReport,
  retrieveOTTPP,
  createOTT,
  deleteOTT,
  updateOTT,
  retrieveEnrolledProgrammesByStatusAndPatient,
  retrieveAllPPs,
  releaseKiosk,
  updateKioskStatus,
  getAdherencePdfDetail,
  fetchExtensionSuspension,
};
