import * as R from 'ramda';
import { httpGet, httpPost, httpPut, httpDelete } from 'apiModule/rest';
import adapter from 'dataModule/adapter';
import { getIdsObject, getQueriesString } from 'utilsModule';

const createNotes = async ({ content: { data } }) =>
  R.pipe(adapter.notes.createNotes.fe2be, httpPost('note/create'), (response) =>
    response.then(adapter.notes.createNotes.be2fe),
  )(data);

  const closePastAlert = async ({ content: { data } }) =>
    R.pipe(adapter.notes.closePastAlert.fe2be, httpPost('closePastAlert'), (response) =>
      response.then(adapter.notes.closePastAlert.be2fe),
    )(data);

const DM = {
  createNotes,
  closePastAlert
};

const ajax = async ({ method, input }) => ({ [method]: await DM[method](input) });

export default { ...DM, ajax };
