import { findBy, switchProp } from 'utilsModule';
import uuidv4 from 'uuid/v4';
import * as R from 'ramda';

const retrieveVitals = {
  fe2be: R.identity,
  be2fe: ({
    value: { vitalGroups: grps, vitalDefs: defs, vitalDimensions: dims, skus }
  }) => ({
    list: R.map(
      ({ id, name, label, defIds, deviceIds: skuIds }) => ({
        id,
        value: name,
        label,
        thresholds: R.pipe(
          R.map(
            R.pipe(
              switchProp('dimensionsIds', 'id', R.__, defs),
              R.map(
                R.pipe(
                  findBy('id', R.__, dims)
                  // R.pick(['id', 'name', 'label', 'step', 'unit', 'lowerBound', 'upperBound']),
                )
              )
            )
          ),
          R.flatten,
          // NOTE: BG - 2 thresholds pre & post
          R.map(
            R.ifElse(
              R.propEq('name', 'bg'),
              R.pipe(R.juxt([R.identity, R.identity]), ([pre, post]) => [
                {
                  ...pre,
                  id: uuidv4(),
                  name: 'bg_pre',
                  label: 'Before Meal'
                },
                {
                  ...post,
                  id: uuidv4(),
                  name: 'bg_post',
                  label: 'After Meal'
                }
              ]),
              R.identity
            )
          ),
          // NOTE: ECG has no threshold
          R.map(
            R.ifElse(
              R.propEq('name', 'ecg'),
              R.assoc('inactive', true),
              R.identity
            )
          ),
          R.flatten
        )(defIds),
        skus: R.map(
          R.pipe(
            findBy('id', R.__, skus),
            // R.pick(['id', 'name', 'type', 'model', 'imageUri']),
            sku => R.assoc('value', R.prop('id', sku), sku),
            sku => R.assoc('label', R.prop('name', sku), sku)
          ),
          skuIds
        ),
        vitalDefs: R.pipe(
          R.innerJoin((def, i) => i === def.id, R.__, defIds),
          R.project([
            'name',
            'label',
            'dimensionsIds',
            'isContinuousMonitoring',
            'isSpot'
          ]),
          R.map(
            ({
              name: name1,
              label: label1,
              dimensionsIds,
              isSpot,
              isContinuousMonitoring
            }) => {
              const vitalDims = R.innerJoin(
                (dim, i) => dim.id === i,
                dims,
                dimensionsIds
              ).map(R.pick(['label', 'name', 'unit', 'step']));
              return {
                name: name1,
                label: label1,
                vitalDims,
                isSpot,
                isContinuousMonitoring
              };
            }
          )
        )(defs)
      }),
      grps
    )
  })
};

const retrieveSkus = {
  fe2be: R.identity,
  be2fe: ({ value: { skus } }) => ({
    list: R.map(
      R.pipe(
        sku => R.assoc('value', R.prop('id', sku), sku),
        sku => R.assoc('label', R.prop('name', sku), sku)
      ),
      skus
    )
  })
};

const retrieveConditions = {
  fe2be: R.identity,
  be2fe: ({ value: { conditions } }) => ({ list: conditions })
};

const retrieveCalendars = {
  fe2be: R.identity,
  be2fe: ({ value: { calendars } }) => ({
    list: R.map(v => R.assoc('id', v.value, v), calendars)
  })
};

export default {
  retrieveVitals,
  retrieveSkus,
  retrieveConditions,
  retrieveCalendars
};
