export const VITAL_TYPE_MAPPING = {
  BP: 'BP',
  BG: 'BG',
  HS: 'HS',
  HR: 'HR',
  RR: 'RR',
  PO: 'PO',
  PO_HR: 'PO_HR',
  BP_HR: 'BP_HR',
  HR_PO: 'PO_HR',
  HR_BP: 'BP_HR',
  ECG: 'ECG',
  BTM: 'BTM',
  HILLROM_WACSM: 'HILLROM_WACSM',
  BP_PO_HR_BTM: 'BP_PO_HR_BTM',
  BP_PO_HR_BTM_RR: 'BP_PO_HR_BTM_RR',
  PO_HR_RR: 'PO_HR_RR'
};

export const VITAL_NAME_MAPPING = {
  [VITAL_TYPE_MAPPING.BP]: 'Blood Pressure',
  [VITAL_TYPE_MAPPING.BG]: 'Blood Glucose',
  [VITAL_TYPE_MAPPING.HS]: 'Weight',
  [VITAL_TYPE_MAPPING.HR]: 'Heart Rate',
  [VITAL_TYPE_MAPPING.RR]: 'Respiration Rate',
  [VITAL_TYPE_MAPPING.PO]: 'SpO2',
  [VITAL_TYPE_MAPPING.PO_HR]: 'SpO2 and Heart Rate',
  [VITAL_TYPE_MAPPING.BP_HR]: 'Blood Pressure and Heart Rate',
  [VITAL_TYPE_MAPPING.ECG]: 'Holter Monitoring Test',
  [VITAL_TYPE_MAPPING.BTM]: 'Temperature',
  [VITAL_TYPE_MAPPING.HILLROM_WACSM]: '4-in-1 Vitals',
  [VITAL_TYPE_MAPPING.BP_PO_HR_BTM]: '4-in-1 Vitals',
  [VITAL_TYPE_MAPPING.BP_PO_HR_BTM_RR]: '5-in-1 Vitals',
  [VITAL_TYPE_MAPPING.PO_HR_RR]: 'Sp02 and Heart Rate and Respiration Rate',
};

export const TASK_STATUS_MAPPING = {
  new: 'Upcoming',
  missed: 'Missed',
  done: 'Completed',
  final: 'Completed',
};

export const VITAL_DISPLAY_MAPPING = {
  'blood glucose': 'Glucose',
  spo2: 'Oxygen',
  'heart rate': 'HR',
  'systolic blood pressure': 'SYS',
  'diastolic blood pressure': 'DIA',
  'body temperature': 'Temp',
  'body weight': 'Weight',
  'respiration rate': 'RR',
};

export const MEALTIME_MAPPING = {
  ACM: 'Before Meals',
  PCM: 'After Meals',
  ACD: 'Before Meals',
  PCD: 'After Meals',
  ACV: 'Before Meals',
  PCV: 'After Meals',
  HS: 'Before Meals',
  PHS: 'After Meals'
};

export const MEALTIME_NAME_MAPPING = {
  ACM: 'Before Breakfast',
  PCM: 'After Breakfast',
  ACD: 'Before Lunch',
  PCD: 'After Lunch',
  ACV: 'Before Dinner',
  PCV: 'After Dinner',
  HS: 'Bedtime',
  PHS: 'After Midnight'
};

export default {
  VITAL_TYPE_MAPPING,
  VITAL_NAME_MAPPING,
  TASK_STATUS_MAPPING,
  MEALTIME_MAPPING,
  MEALTIME_NAME_MAPPING
};
