import * as R from 'ramda';

const createNotes = {
  fe2be: R.identity,
  be2fe: R.identity,
};

const closePastAlert = {
  fe2be: R.identity,
  be2fe: R.identity,
};

export default {
  createNotes,
  closePastAlert
};
